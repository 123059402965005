import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import "moment/locale/it";
import { Plugins } from "@capacitor/core";
import { Platform } from "@ionic/angular";
import { PushService } from "./notification/services/push.service";
import { AnalyticsService } from "./core/services/analytics.service";
import { MediaQueryService } from "./core/services/media-query.service";
import { NGXLogger } from "ngx-logger";

@Component({
  selector: "app-root",
  template: `
    <ion-app>
      <app-nav-sidebar></app-nav-sidebar>
      <notifier-container></notifier-container>
      <ion-router-outlet
        id="main"
        [swipeGesture]="false"
        [animated]="!isDesktop"
      ></ion-router-outlet>
    </ion-app>
  `,
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  isDesktop = false;

  constructor(
    private translate: TranslateService,
    private platform: Platform,
    private pushService: PushService,
    private logger: NGXLogger,
    analyticsService: AnalyticsService,
    mediaQueryService: MediaQueryService
  ) {
    translate.setDefaultLang("it");
    moment.locale("it");
    // this.getDeviceLanguage().then(language => {
    //   let value = language.value.substr(0, 2);
    //   value = 'en';
    //   value = ['it', 'en'].includes(value) ? value : 'it';
    //   this.translate.setDefaultLang(value);
    //   moment.locale(value);
    // });
    mediaQueryService.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });

    this.platform.ready().then(() => {
      analyticsService.init();
      this.initCordovaIosRtc();
      this.getDeviceInfo().then((info) => {
        const platform = info.platform;
        if (platform !== "web") {
          // noinspection JSIgnoredPromiseFromCall
          Plugins.SplashScreen.hide();
        }
        if (platform === "android") {
          // noinspection JSIgnoredPromiseFromCall
          Plugins.StatusBar.setBackgroundColor({
            color: "#244c59", // --app-text-color-brand
          });
        }
        this.pushService.init(platform);
      });
    });
  }

  private initCordovaIosRtc() {
    // @ts-ignore
    const cordova = window.cordova;
    if (cordova && cordova.plugins && cordova.plugins.iosrtc) {
      this.logger.debug("[iosrtc] has plugin");

      // Patch MediaStreamTrack with clone
      // MediaStreamTrack.prototype.clone = function () {
      //   return this;
      // };

      cordova.plugins.iosrtc.registerGlobals();
      const needMic = true;
      const needCamera = true;
      cordova.plugins.iosrtc.requestPermission(
        needMic,
        needCamera,
        (permissionApproved: any) => {
          // permissionApproved will be true if user accepted permission otherwise will be false.
          this.logger.debug(
            "[iosrtc] requestPermission status: ",
            permissionApproved ? "Approved" : "Rejected"
          );
        }
      );
    }
  }

  async getDeviceInfo() {
    return await Plugins.Device.getInfo();
  }

  async getDeviceLanguage() {
    return await Plugins.Device.getLanguageCode();
  }
}
