/**
 * @see http://127.0.0.1:8000/swagger-ui.html
 */
import { environment } from "./environment";

export interface AppointmentListCriteria {
  doctorId?: number;
  patientId?: number;
  month: number;
  year: number;
}

export const APIEndpoints = {
  // public api
  login: "/api/v1/login",
  resetPasswordRequest: "/api/v1/reset-password-request",
  resetPassword: "/api/v1/reset-password",
  // private api
  getProfile: "/api/v1/me",
  getPathologyList: "/api/v1/pathology",
  getMeasurementTypologyList: "/api/v1/measurement-typology",
  putAvatar: "/api/v1/avatar",
  postGDPR: "/api/v1/gdpr",
  // doctor api
  getPatientList: "/api/v1/patient/", // GET|POST
  postDevice: "/api/v1/user/add-device/", // POST
  getActivityLogs: "/api/v1/activity-logs/",
  updatePatientProfile: (patientId) => `/api/v1/patient/${patientId}/`, // PUT
  deletePatientProfile: (patientId) => `/api/v1/patient/${patientId}/`, // DELETE
  updatePatientPathologies: (patientId) =>
    `/api/v1/patient/${patientId}/pathology/`, // PATCH
  updateAppointment: (appointmentId) => `/api/v1/appointment/${appointmentId}/`, // PUT
  getAppointmentList: (criteria: AppointmentListCriteria) =>
    criteria.patientId
      ? `/api/v1/patient/${criteria.patientId}/appointment/month/${criteria.month}/year/${criteria.year}/`
      : `/api/v1/doctor/${criteria.doctorId}/appointment/month/${criteria.month}/year/${criteria.year}/`,
  postAppointment: "/api/v1/appointment/",

  // patient request
  postPatientRequest: "/api/v1/patient-request/",
  updatePatientRequest: (id) => `/api/v1/patient-request/${id}`, // PUT

  // chat/video api
  getPatientVideocall: (patientId, doctorId = null) => `/api/v1/patient/${patientId}/videocall/`
    + `${doctorId ? ('?doctorId=' + doctorId) : ''}`, // POST|GET
  updateVideocall: (videocallId) => `/api/v1/videocall/${videocallId}`, // PUT
  getPatientChat: (patientId, doctorId = null) => `/api/v1/patient/${patientId}/chat/`
    + `${doctorId ? ('?doctorId='+doctorId) : ''}`,
  getUnreadChat: "/api/v1/chat/unread",

  // patient api
  getDoctorList: "/api/v1/doctor/", // GET
  postMeasurement: "/api/v1/measurement",
  getMeasurements: (patientId, typologyId) =>
    `/api/v1/patient/${patientId}/measurement-typology/${typologyId}/measurements`,
  getMeasurementChart: (patientId, typologyId) =>
    `/api/v1/patient/${patientId}/measurement-typology/${typologyId}/measurements/chart`,
  getNotificationList: "/api/v1/notifications",
  getUnreadNotificationCount: "/api/v1/notifications/count",
  markAllReadNotification: "/api/v1/notifications/readall", // POST

  updateDoctorProfile: (doctorId) => `/api/v1/doctor/${doctorId}/`, // PUT
  changePassword: (userId) => `/api/v1/user/${userId}/change-password/`, //PUT

  // therapy api
  getTherapyList: (patientId, pathologyId) =>
    `/api/v1/patient/${patientId}/pathology/${pathologyId}/therapy`, // GET
  postTherapy: "/api/v1/therapy/", // POST
  therapy: (therapyId: number) => `/api/v1/therapy/${therapyId}/`, //PUT|DELETE
  drug: "/api/v1/drug/",

  // note
  getNoteList: (patientId, pathologyId) =>
    `/api/v1/patient/${patientId}/pathology/${pathologyId}/note`,
  postNote: (patientId, pathologyId) =>
    `/api/v1/patient/${patientId}/pathology/${pathologyId}/note`,
  getNote: (noteId) => `/api/v1/note/${noteId}/`, // PUT|DELETE

  // comorbidity
  postComorbidity: (patientId, pathologyId) =>
    `/api/v1/patient/${patientId}/pathology/${pathologyId}/comorbidity`,
  getComorbidityList: (patientId, pathologyId) =>
    `/api/v1/patient/${patientId}/pathology/${pathologyId}/comorbidity`,
  getComorbidity: (comorbidityId) => `/api/v1/comorbidity/${comorbidityId}/`, // PUT|DELETE

  // appointment rule
  getAppointmentRuleList: () => '/api/v1/appointment-rule/',
  postAppointmentRule: "/api/v1/appointment-rule/", // POST
  appointmentRule: (appointmentRuleId: number) => `/api/v1/appointment-rule/${appointmentRuleId}/`, //PUT|DELETE
};

export const isPublicEndpoint = (url: string): boolean => {
  return (
    url.includes(environment.wp_url) ||
    url.includes("login") ||
    url.includes("reset-password") ||
    url.includes("assets/i18n")
  );
};
