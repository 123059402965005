/* tslint:disable:variable-name */
import {Model} from '../core/models';
import * as moment from 'moment';
import {Doctor} from '../doctor/models';

export class Patient extends Model {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  patient_id: number;
  active: boolean;
  start_date: string;
  startDate: moment.Moment;
  pathology_ids: number[];
  avatar: string;
  doctors: Doctor[];


  deserialize(input: any): this {
    return super.deserialize({
      ...input,
      startDate: moment(input.start_date),
      id: input.patient_id,
      avatar: input.avatar || 'assets/icon/user-circle.svg',
      doctors: input.doctors.map(x => (new Doctor()).deserialize(x)),
      phone: input.phone.replace('+39', ''),
    });
  }

  getFullName(): string {
    return this.first_name + ' ' + this.last_name;
  }
}
