/* tslint:disable:variable-name */
import { Model } from "../core/models";
import { array_has } from "../core/utils/ararys";
import {Doctor} from '../doctor/models';

export enum RoleEnum {
  GENERAL_PRACTITIONER = "general_practitioner_role",
  PATIENT = "patient_role",
  ADMIN = "admin_role",
}

export enum Role {
  Anonymous = "Anonymous",
  User = "User",
  Patient = "Patient",
  Doctor = "Doctor",
  Admin = "Admin",
}

export abstract class Profile extends Model {
  id: number;
  first_name: string;
  last_name: string;
  phone: number;
  email: string;
  fiscal_code: string;
  cap: string;
  city: string;
  address: string;
  role: Role;
  roles: RoleEnum[];
  avatar: string;
  doctor_name: string;
  doctor_avatar: string;
  gdpr: boolean;

  static factory(input: { role: string }): Profile {
    return ("doctor_id" in input
      ? new DoctorProfile()
      : new PatientProfile()
    ).deserialize(input);
  }

  deserialize(input: any): this {
    return super.deserialize({
      ...input,
      avatar: input.avatar || "assets/icon/user-circle.svg",
      doctor_avatar: input.doctor_avatar || "assets/icon/user-circle.svg",
    });
  }

  getRole(): Role {
    return this.role;
  }

  getRoles(): RoleEnum[] {
    return this.roles;
  }

  hasRole(roles: Role[] | Role | RoleEnum[] | RoleEnum) {
    return (
      array_has(this.getRoles(), roles) || array_has(this.getRole(), roles)
    );
  }

  getDashboardRoute() {
    return this.hasRole(Role.Doctor) ? "/doctor" : "/patient";
  }

  getFullName() {
    return this.first_name + " " + this.last_name;
  }
}

export class DoctorProfile extends Profile {
  role = Role.Doctor;
  doctor_id: number;
  stats: any;
}

export class PatientProfile extends Profile {
  role = Role.Patient;
  pathology_ids: number[];
  patient_id: number;
  doctor_name: string;
  doctor_avatar: string;
  doctors: Doctor[];

  deserialize(input: any): this {
    return super.deserialize({
      ...input,
      doctors: input.doctors.map(x => (new Doctor()).deserialize(x)),
    });
  }
}

export class AnonymousProfile extends Profile {
  role = Role.Anonymous;
}
