import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {NotifierModule} from 'angular-notifier';
import {LoggerModule} from 'ngx-logger';
import {getTranslateRootModule} from './core/utils/translate-loader';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {CoreModule} from './core/core.module';
import {JwtBearerInterceptor} from './core/interceptors/jwt-bearer.interceptor';
import {ApiOfflineInterceptor} from './core/interceptors/api-offline.interceptor';

import * as Sentry from '@sentry/angular';
import {Angulartics2Module} from "angulartics2";

import {LayoutModule} from "@angular/cdk/layout";

export const NOTIFICATION_HIDE_DELAY = 3000;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    getTranslateRootModule(),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NotifierModule.withConfig(
      {
        position: {
          vertical: {
            position: 'top',
            distance: 0,
            gap: 0
          },
          horizontal: {
            distance: 0
          }
        },
        behaviour: {
          autoHide: NOTIFICATION_HIDE_DELAY,
        }
      }
    ),
    LoggerModule.forRoot(environment.logging),
    CoreModule,
    LayoutModule,
    Angulartics2Module.forRoot({
      gst: {
        trackingIds: [environment.analytics?.GA_MEASUREMENT_ID],
        anonymizeIp: true,
      },
      pageTracking: {
        clearIds: true,
      },
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      }),
    },
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtBearerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiOfflineInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
