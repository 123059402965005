import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if(environment.sentry) {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: environment.sentry.tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.sentry.tracesSampleRate,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// noinspection JSIgnoredPromiseFromCall
defineCustomElements(window);
