import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Role } from "./auth/models";
import { AuthGuard } from "./auth/services/auth.guard";
import { PathologyResolver } from "./pathology/services/pathology.resolver";
import { ProfileResolver } from "./auth/services/profile.resolver";
import { PatientResolver } from "./patient/services/patient.resolver";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "register",
    data: { roles: [Role.Anonymous] },
    canActivate: [AuthGuard],
    loadChildren: "./public/register/register.module#RegisterPageModule",
  },
  // { path: 'members', loadChildren: './members/member-routing.module#MemberRoutingModule', canActivate: [AuthGuardService]},
  {
    path: "login",
    data: { roles: [Role.Anonymous] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./auth/pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "reset-password-request",
    data: { roles: [Role.Anonymous] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./auth/pages/reset-password-request/reset-password-request.module"
      ).then((m) => m.ResetPasswordRequestPageModule),
  },
  {
    path: "reset-password",
    data: { roles: [Role.Anonymous] },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./auth/pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: "patient-requests/:patientRequestId",
    resolve: {
      profile: ProfileResolver,
    },
    loadChildren: () =>
      import(
        "./doctor/pages/doctor-patient-request-detail/doctor-patient-request-detail.module"
        ).then((m) => m.DoctorPatientRequestDetailPageModule),
  },
  {
    path: "doctor",
    runGuardsAndResolvers: "always",
    data: { roles: [Role.Doctor] },
    resolve: {
      profile: ProfileResolver,
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./doctor/pages/doctor-dashboard/doctor-dashboard.module"
          ).then((m) => m.DoctorDashboardPageModule),
      },
      {
        path: "privacy",
        loadChildren: () =>
          import("./doctor/pages/doctor-privacy/doctor-privacy.module").then(
            (m) => m.DoctorPrivacyPageModule
          ),
      },
      {
        path: "patients",
        loadChildren: () =>
          import(
            "./patient/pages/doctor-patient-list/doctor-patient-list.module"
          ).then((m) => m.DoctorPatientListPageModule),
      },
      {
        path: "patients/:patientId",
        resolve: {
          patient: PatientResolver,
        },
        children: [
          {
            path: "",
            loadChildren: () =>
              import(
                "./patient/pages/doctor-patient-detail/doctor-patient-detail.module"
              ).then((m) => m.DoctorPatientDetailPageModule),
          },
          {
            path: "chat",
            loadChildren: () =>
              import(
                "./chat/pages/doctor-patient-chat/doctor-patient-chat.module"
              ).then((m) => m.DoctorPatientChatModule),
          },
          {
            path: "videocall",
            loadChildren: () =>
              import(
                "./chat/pages/doctor-patient-videocall/doctor-patient-videocall.module"
              ).then((m) => m.DoctorPatientVideocallModule),
          },
          {
            path: "videocall-history",
            loadChildren: () =>
              import(
                "./chat/pages/doctor-patient-videocall-history/doctor-patient-videocall-history.module"
              ).then((m) => m.DoctorPatientVideocallHistoryPageModule),
          },
        ],
      },
      {
        path: "patients/:patientId/pathology/:pathologyId",
        resolve: {
          patient: PatientResolver,
          pathology: PathologyResolver,
        },
        children: [
          {
            path: "",
            loadChildren: () =>
              import(
                "./pathology/pages/doctor-patient-pathology-detail/doctor-patient-pathology-detail.module"
              ).then((m) => m.DoctorPatientPathologyDetailPageModule),
          },
          {
            path: "measurements-last",
            loadChildren: () =>
              import(
                "./measurement/pages/doctor-patient-pathology-measurement-last/doctor-patient-pathology-measurement-last.module"
              ).then((m) => m.DoctorPatientPathologyMeasurementLastPageModule),
          },
          {
            path: "measurements-history",
            loadChildren: () =>
              import(
                "./measurement/pages/doctor-patient-pathology-measurement-history/doctor-patient-pathology-measurement-history.module"
              ).then(
                (m) => m.DoctorPatientPathologyMeasurementHistoryPageModule
              ),
          },
          {
            path: "therapies",
            loadChildren: () =>
              import(
                "./therapy/pages/doctor-patient-therapy-list/doctor-patient-therapy-list.module"
              ).then((m) => m.DoctorPatientTherapyListPageModule),
          },
          {
            path: "notes",
            loadChildren: () =>
              import(
                "./note/pages/doctor-patient-note-list/doctor-patient-note-list.module"
              ).then((m) => m.DoctorPatientNoteListPageModule),
          },
          {
            path: "comorbidities",
            loadChildren: () =>
              import(
                "./comorbidity/pages/doctor-patient-comorbidity-list/doctor-patient-comorbidity-list.module"
              ).then((m) => m.DoctorPatientComorbidityListPageModule),
          },
        ],
      },
      {
        path: "patients-add",
        loadChildren: () =>
          import(
            "./patient/pages/doctor-patient-add/doctor-patient-add.module"
          ).then((m) => m.DoctorPatientAddPageModule),
      },
      {
        path: "pathologies",
        loadChildren: () =>
          import(
            "./pathology/pages/doctor-pathology-list/doctor-pathology-list.module"
          ).then((m) => m.DoctorPathologyListPageModule),
      },
      {
        path: "pathologies/:pathologyId",
        resolve: {
          pathology: PathologyResolver,
        },
        loadChildren: () =>
          import(
            "./pathology/pages/doctor-pathology-detail/doctor-pathology-detail.module"
          ).then((m) => m.DoctorPathologyDetailPageModule),
      },
      {
        path: "pathologies/:pathologyId/patients",
        resolve: {
          pathology: PathologyResolver,
        },
        loadChildren: () =>
          import(
            "./patient/pages/doctor-patient-list/doctor-patient-list.module"
          ).then((m) => m.DoctorPatientListPageModule),
      },
      {
        path: "appointments",
        loadChildren: () =>
          import(
            "./appointment/pages/doctor-appointment-list/doctor-appointment-list.module"
          ).then((m) => m.DoctorAppointmentListPageModule),
      },
      {
        path: "appointments-add",
        loadChildren: () =>
          import(
            "./appointment/pages/doctor-appointment-add/doctor-appointment-add.module"
          ).then((m) => m.DoctorAppointmentAddPageModule),
      },
      {
        path: "appointment-rules",
        loadChildren: () =>
          import(
            "./appointment/pages/doctor-appointment-rule-list/doctor-appointment-rule-list.module"
            ).then((m) => m.DoctorAppointmentRuleListPageModule),
      },

      {
        path: "therapies-add",
        loadChildren: () =>
          import(
            "./therapy/pages/doctor-therapy-add/doctor-therapy-add.module"
          ).then((m) => m.DoctorTherapyAddPageModule),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import(
            "./notification/pages/notification-board/notification-board.module"
          ).then((m) => m.NotificationBoardPageModule),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./auth/pages/profile/profile.module").then(
            (m) => m.ProfilePageModule
          ),
      },
      {
        path: "activity",
        loadChildren: () =>
          import("./auth/pages/activity/activity.module").then(
            (m) => m.ActivityPageModule
          ),
      },
    ],
  },
  {
    path: "patient",
    runGuardsAndResolvers: "always",
    canActivate: [AuthGuard],
    resolve: {
      profile: ProfileResolver,
    },
    data: { roles: [Role.Patient] },
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "./patient/pages/patient-dashboard/patient-dashboard.module"
          ).then((m) => m.PatientDashboardPageModule),
      },
      {
        path: "chat",
        loadChildren: () =>
          import("./chat/pages/patient-chat/patient-chat.module").then(
            (m) => m.PatientChatModule
          ),
      },
      {
        path: "videocall",
        loadChildren: () =>
          import(
            "./chat/pages/patient-videocall/patient-videocall.module"
          ).then((m) => m.PatientVideocallModule),
      },
      {
        path: "doctor-search",
        loadChildren: () =>
          import(
            "./doctor/pages/patient-doctor-search/patient-doctor-search.module"
            ).then((m) => m.PatientDoctorSearchModule),
      },
      {
        path: "pathologies/:pathologyId",
        resolve: {
          pathology: PathologyResolver,
        },
        children: [
          {
            path: "",
            loadChildren: () =>
              import(
                "./pathology/pages/patient-pathology-detail/patient-pathology-detail.module"
              ).then((m) => m.PatientPathologyDetailPageModule),
          },
          {
            path: "therapies",
            loadChildren: () =>
              import(
                "./therapy/pages/patient-therapy-list/patient-therapy-list.module"
              ).then((m) => m.PatientTherapyListPageModule),
          },
          {
            path: "measurements",
            loadChildren: () =>
              import(
                "./measurement/pages/patient-pathology-measurement/patient-pathology-measurement.module"
              ).then((m) => m.PatientPathologyMeasurementPageModule),
          },
          {
            path: "measurements/history",
            loadChildren: () =>
              import(
                "./measurement/pages/patient-pathology-measurement-history/patient-pathology-measurement-history.module"
              ).then((m) => m.PatientPathologyMeasurementHistoryPageModule),
          },
          {
            path: "measurements/add",
            loadChildren: () =>
              import(
                "./measurement/pages/patient-pathology-measurement-add/patient-pathology-measurement-add.module"
              ).then((m) => m.PatientPathologyMeasurementAddPageModule),
          },
        ],
      },
      {
        path: "therapies",
        loadChildren: () =>
          import(
            "./therapy/pages/patient-therapy-list/patient-therapy-list.module"
          ).then((m) => m.PatientTherapyListPageModule),
      },
      {
        path: "appointments",
        loadChildren: () =>
          import(
            "./appointment/pages/patient-appointment-list/patient-appointment-list.module"
          ).then((m) => m.PatientAppointmentListPageModule),
      },
      {
        path: 'appointments-add',
        loadChildren: () => import('./appointment/pages/patient-appointment-add/patient-appointment-add.module').then(
          m => m.PatientAppointmentAddPageModule)
      },
      {
        path: "notifications",
        loadChildren: () =>
          import(
            "./notification/pages/notification-board/notification-board.module"
          ).then((m) => m.NotificationBoardPageModule),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./auth/pages/profile/profile.module").then(
            (m) => m.ProfilePageModule
          ),
      },
      {
        path: "privacy",
        loadChildren: () =>
          import("./patient/pages/patient-privacy/patient-privacy.module").then(
            (m) => m.PatientPrivacyPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
