import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {ApiService} from './api.service';
import {Credentials} from '../../auth/types';
import {APIEndpoints} from '../../../environments/api-endpoints';

const TIME_OFFSET = 180;

interface JwtToken {
  access_token: string;
  expires_in: number;
  expireDate?: number;
}

@Injectable({
  providedIn: 'root'
})
export class JwtBearerStore {
  private token: JwtToken;
  private credentials: Credentials;

  constructor(
    private logger: NGXLogger,
    private apiService: ApiService,
  ) {
  }

  clearToken() {
    this.token = null;
  }

  getToken(): Observable<string> {
    return this.isValidToken()
      ? of(this.token.access_token)
      : this.fetchToken(this.credentials);
  }

  setCredentials(credentials) {
    this.credentials = credentials;
  }

  private isValidToken() {
    return this.token && this.token.expireDate >= new Date().getTime();
  }

  private fetchToken(credentials: Credentials): Observable<string> {
    this.logger.debug('[JwtBearerStore] fetching new token');
    return this.apiService.post(APIEndpoints.login, credentials)
      .pipe(map(data => {
        this.logger.debug('[JwtBearerStore] fetched');
        data.expireDate = new Date().getTime() + (1000 * data.expires_in) - (TIME_OFFSET * 1000);
        this.token = data;
        return data.access_token;
      }));
  }
}
