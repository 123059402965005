import {Injectable} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {APIEndpoints} from '../../../environments/api-endpoints';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, take, tap} from 'rxjs/operators';
import {ProfileStore} from '../../auth/services/profile.store';
import {NGXLogger} from 'ngx-logger';
import {Patient} from '../models';
import {Pathology} from '../../pathology/models';
import parsePhoneNumberFromString from "libphonenumber-js";

@Injectable({
  providedIn: 'root'
})
export class PatientStore {
  private current: Patient;
  private patientListSubject = new BehaviorSubject<Patient[]>(null);
  patientList$: Observable<Patient[]> = this.patientListSubject.asObservable().pipe(filter(x => x !== null));
  unreadChatList$ = new BehaviorSubject([]);

  constructor(
    private apiService: ApiService,
    private profileStore: ProfileStore,
    private logger: NGXLogger
  ) {
    this.profileStore.profile$.subscribe((profile) => {
      if (profile && !this.patientListSubject.getValue()) {
        this.fetchAll().subscribe((patients) => {
          this.patientListSubject.next(patients);
        });
      }
    });
  }

  private fetchAll(): Observable<Patient[]> {
    return this.apiService.get(
      APIEndpoints.getPatientList
    ).pipe(map(
      data => {
        return data ? data.map(x => (new Patient()).deserialize(x)) : null;
      }
    ));
  }

  getById(id: number) {
    return this.patientList$.pipe(
      map(
        (pathologies) => pathologies && pathologies.find(
          patient => patient.id === id
        ),
        filter(x => !!x)
      ),
      take(1),
    );
  }

  create(value: object) {
    const data = {
      ...value,
      phone: parsePhoneNumberFromString(value['phone'], 'IT').format("E.164")
    };
    return this.apiService.post(APIEndpoints.getPatientList, data).pipe(
      map(x => {
        return x;
      })
    );
  }

  addPathology(patient: Patient, pathology: Pathology) {
    return this.addPathologyById(patient, pathology.id);
  }

  addPathologyById(patient: Patient, pathologyId: number) {
    const ids = [...patient.pathology_ids, pathologyId];
    return this.updatePathologies(patient, ids);
  }

  removePathology(patient: Patient, pathology: Pathology) {
    const ids = patient.pathology_ids.filter(id => id !== pathology.id);
    return this.updatePathologies(patient, ids);
  }

  private updatePathologies(patient: Patient, pathologyIds: number[]) {
    return this.apiService.patch(
      APIEndpoints.updatePatientPathologies(patient.id),
      {pathology_ids: pathologyIds}
    ).pipe(
      map(data => {
        patient.pathology_ids = pathologyIds;
        this.patientListSubject.next(this.patientListSubject.getValue());
        return data;
      })
    );
  }

  delete(patientId) {
    console.log(APIEndpoints.deletePatientProfile(patientId));
    return this.apiService.delete(
      APIEndpoints.deletePatientProfile(patientId)
    ).pipe(
      tap(() => {
        const newList = this.patientListSubject.getValue().filter(x => x.id !== patientId);
        this.patientListSubject.next(newList);
      })
    )
  }

  setCurrent(current: Patient = null) {
    this.current = current;
  }

  getCurrent() {
    return this.current;
  }

  loadPatients(): Observable<Patient[]> {
    return this.fetchAll().pipe(
      tap((patients) => {
        this.patientListSubject.next(patients);
      })
    );
  }

  loadUnreadChat(): Observable<any> {
    return this.apiService.get(
      APIEndpoints.getUnreadChat
    ).pipe(
      tap(data => console.log(data)),
      tap(data => this.unreadChatList$.next(data))
    )
  }
}
