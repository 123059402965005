import {Injectable} from '@angular/core';

import {BreakpointObserver} from "@angular/cdk/layout";
import {ProfileStore} from "../../auth/services/profile.store";
import {BehaviorSubject, combineLatest, zip} from "rxjs";
import {Role} from "../../auth/models";
import {combineAll} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  isDesktop$ = new BehaviorSubject(false);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private profileStore: ProfileStore
  ) {
    combineLatest([
      this.breakpointObserver.observe(['(min-width: 992px)']),
      this.profileStore.profile$
    ]).subscribe(
      ([{matches}, profile]) => {
        // we disable desktop mode for patients
        if (!profile.hasRole(Role.Doctor)) {
          matches = false;
        }
        this.isDesktop$.next(matches)
      }
    );
  }
}
