import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { APIEndpoints } from "../../../environments/api-endpoints";
import { ApiService } from "../../core/services/api.service";
import {
  AnonymousProfile,
  DoctorProfile,
  PatientProfile,
  Profile,
} from "../models";
import { NGXLogger } from "ngx-logger";
import { LoadingController } from "@ionic/angular";

const LOG_PREFIX = '[Store][Profile] '

@Injectable({
  providedIn: "root",
})
export class ProfileStore {
  private profileSubject = new BehaviorSubject(null);
  profile$: Observable<Profile> = this.profileSubject
    .asObservable()
    .pipe(filter((x) => !!x));

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private logger: NGXLogger,
    private loadingController: LoadingController
  ) {
    authService.authentication$.subscribe((state) => {
      if (state) {
        this.fetchProfile().subscribe();
      } else {
        this.profileSubject.next(new AnonymousProfile());
      }
    });
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: "Attendere...<br/>Caricamento profilo",
    });
    loading.present();
    return loading;
  }

  fetchProfile(): Observable<Profile> {
    return from(this.presentLoading()).pipe(
      switchMap((loading) => {
        return this.apiService.get(APIEndpoints.getProfile).pipe(
          catchError((error) => {
            this.authService.logout();
            return error;
          }),
          map((data) => Profile.factory(data)),
          tap((profile) => {
            this.profileSubject.next(profile);
            loading.dismiss();
          })
        );
      })
    );
  }

  setAvatar(ImageURL: string | ArrayBuffer) {
    return this.apiService.put(APIEndpoints.putAvatar, { avatar: ImageURL });
  }

  updateGDPR() {
    return this.apiService
      .post(APIEndpoints.postGDPR, { gdpr: true })
      .pipe(switchMap(() => this.fetchProfile()));
  }

  update(profile: Profile, data: any) {
    const endpoint =
      profile instanceof PatientProfile
        ? APIEndpoints.updatePatientProfile(profile.patient_id)
        : profile instanceof DoctorProfile
        ? APIEndpoints.updateDoctorProfile(profile.doctor_id)
        : null;
    return this.apiService.put(endpoint, data);
  }

  changePassword(profile: Profile, data: any) {
    return this.apiService.post(APIEndpoints.changePassword(profile.id), data);
  }

  getProfile() {
    return this.profileSubject.getValue();
  }
}
