import {Deserializable} from './types';
import * as moment from 'moment';

export abstract class Model implements Deserializable {
  id: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

type NotificationType = 'MESSAGE' | 'TODO';

export class Notification extends Model {
  createdAt: moment.Moment;
  type: NotificationType;
  title: string;
  body: string;
  isRead: boolean;

  deserialize(input: any): this {
    input.createdAt = moment();
    super.deserialize(input);
    return this;
  }
}
