import {Plugins} from '@capacitor/core';

export function clearStorageObject(key) {
  // noinspection JSIgnoredPromiseFromCall
  Plugins.Storage.remove(key);
}

export function setStorageObject(key, obj) {
  // noinspection JSIgnoredPromiseFromCall
  Plugins.Storage.set({key, value: JSON.stringify(obj)});
}

export async function getStorageObject(key) {
  const ret = await Plugins.Storage.get({key});
  return JSON.parse(ret.value);
}
