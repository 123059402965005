import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const factory = (http: HttpClient) => {
     return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export function getTranslateRootModule() {
  return TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: factory,
      deps: [HttpClient]
    }
  });
}
