import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';

import {ApiService} from '../../core/services/api.service';
import {APIEndpoints} from '../../../environments/api-endpoints';
import {JwtBearerStore} from '../../core/services/jwt-bearer.store';
import {getStorageObject, setStorageObject} from '../../core/utils/storage';
import {Credentials} from '../types';

const CREDENTIALS_KEY = 'profile$';

interface ResetPasswordData {
  reset_code: string;
  password: string;
  password_confirm: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private credentialsSubject = new BehaviorSubject(null);
  credentials$: Observable<Credentials> = this.credentialsSubject.asObservable();
  private authenticationSubject = new BehaviorSubject(null);
  authentication$: Observable<boolean> = this.authenticationSubject.asObservable().pipe(filter(x => x !== null));

  constructor(
    private logger: NGXLogger,
    private apiService: ApiService,
    private jwtService: JwtBearerStore
  ) {
    getStorageObject(CREDENTIALS_KEY).then(res => {
      if (res) {
        this.logger.debug('[AuthenticationService] cached login');
        this.credentialsSubject.next(res);
        this.authenticationSubject.next(true);
      } else {
        this.logger.debug('[AuthenticationService] anonymous');
        this.authenticationSubject.next(false);
      }
    });
    this.credentials$.subscribe(
      (credentials) => this.jwtService.setCredentials(credentials)
    );
  }

  login(credentials: Credentials): Observable<boolean> {
    this.logger.debug('[AuthenticationService] login');
    this.jwtService.clearToken();
    this.jwtService.setCredentials(credentials);
    return this.jwtService.getToken()
      .pipe(map(
        () => {
          this.logger.debug('[AuthenticationService] login done');
          // noinspection JSIgnoredPromiseFromCall
          setStorageObject(CREDENTIALS_KEY, credentials);
          this.credentialsSubject.next(credentials);
          this.authenticationSubject.next(true);
          return true;
        })
      );
  }

  resetPasswordRequest(phone) {
    return this.apiService.post(APIEndpoints.resetPasswordRequest, {phone});
  }

  resetPassword(data: ResetPasswordData) {
    return this.apiService.post(APIEndpoints.resetPassword, data);
  }

  logout() {
    this.logger.debug('[AuthenticationService] logout');
    // noinspection JSIgnoredPromiseFromCall
    setStorageObject(CREDENTIALS_KEY, null);
    this.authenticationSubject.next(false);
    this.credentialsSubject.next(null);
  }
}
