import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {PatientStore} from './patient.store';
import {Patient} from '../models';
import {tap} from "rxjs/operators";
import {NotifierService} from "angular-notifier";

@Injectable({providedIn: 'root'})
export class PatientResolver implements Resolve<Patient> {
  constructor(
    private patientStore: PatientStore,
    private router: Router,
    private notifier: NotifierService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const id = parseInt(route.paramMap.get('patientId'), 10);
    const ret = this.patientStore.getById(id);
    ret
      .pipe(
        tap(x => {
          if(!x) {
            this.notifier.notify('error', 'Paziente non trovato');
            this.router.navigateByUrl('/')
          };
        })
      )
      .subscribe(x => this.patientStore.setCurrent(x));
    return ret;
  }
}
