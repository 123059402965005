import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, retry, timeout} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {ApiOfflineInterceptorHttpParams} from '../services/api-offline.interceptor.interfaces';
import {ApiOfflineStore} from '../services/api-offline.store';
import {NotifierService} from 'angular-notifier';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

@Injectable()
export class ApiOfflineInterceptor implements HttpInterceptor {
  private isErrorNotified: boolean;

  constructor(
    private logger: NGXLogger,
    private apiOfflineStore: ApiOfflineStore,
    private notifierService: NotifierService,
    private translate: TranslateService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params instanceof ApiOfflineInterceptorHttpParams) {
      if (!this.apiOfflineStore.isOnline()) {
        return this.handleOfflineError(request, request.params);
      }

      return next.handle(request).pipe(
        timeout(environment.MAX_AJAX_TIMEOUT),
        catchError((err: HttpErrorResponse) => {
            if (request.params instanceof ApiOfflineInterceptorHttpParams) {
              return this.handleOfflineError(request, request.params, err);
            }
          }
        )
      );
    }
    return next.handle(request);
    // return next.handle(request).pipe(
    //   timeout(environment.MAX_AJAX_TIMEOUT),
    //   // retry(1),
    //   catchError((err: HttpErrorResponse) => {
    //     console.log(err.status);
    //     if (![400, 422].includes(err.status)) {
    //       this.handleStandardError(err);
    //     }
    //     return err;
    //   })
    // );
  }

  private handleStandardError(error: HttpErrorResponse) {
    if (!this.isErrorNotified) {
      this.notifierService.notify('error', this.translate.instant('OFFLINE_ERROR'));
      this.isErrorNotified = true;
      setTimeout(() => {
        this.isErrorNotified = false;
      }, environment.NOTIFICATION_HIDE_DELAY);
    }
    return throwError(error);
    // return of(null);
  }

  private handleOfflineError(request, params: ApiOfflineInterceptorHttpParams, err?: HttpErrorResponse) {
    const offlineSettings = params.interceptorConfig;
    const route = offlineSettings.route;
    this.logger.debug('[ApiOfflineInterceptor] handle error for ' + route);
    let responseBody;
    responseBody = this.apiOfflineStore.handleRequestError(request, offlineSettings);
    this.logger.debug('[ApiOfflineInterceptor] returned fake response for ' + route + ': ' + JSON.stringify(responseBody));
    return of(new HttpResponse(responseBody));
  }

}
