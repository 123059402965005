import {Injectable} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {APIEndpoints} from '../../../environments/api-endpoints';
import {Pathology} from '../models';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, share, shareReplay, take, tap} from 'rxjs/operators';
import {ProfileStore} from '../../auth/services/profile.store';
import {NGXLogger} from 'ngx-logger';

const LOG_PREFIX = '[Store][Pathology] ';

@Injectable({
  providedIn: 'root'
})
export class PathologyStore {
  pathologyList$: Observable<Pathology[]>;
  private current: Pathology;

  constructor(
    private apiService: ApiService,
    private profileStore: ProfileStore,
    private logger: NGXLogger
  ) {
    this.pathologyList$ = this.initList();
  }

  private initList(): Observable<Pathology[]> {
    return this.apiService.get(
      APIEndpoints.getPathologyList
    ).pipe(
      map(
        data => {
          this.logger.debug(LOG_PREFIX + 'fetched');
          return data ? data.map(x => (new Pathology()).deserialize(x)) : null;
        }
      ),
      shareReplay(),
      take(1)
    );
  }

  getById(id: number): Observable<Pathology> {
    return this.pathologyList$.pipe(
      take(1),
      map(
        (pathologyList) =>
          pathologyList && pathologyList.find(
          pathology => pathology.id === id
          ),
        // filter(x => !!x)
      ),
    );
  }

  setCurrent(current: Pathology = null) {
    this.current = current
  }

  getCurrent() {
    return this.current;
  }
}
