import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {Plugins} from '@capacitor/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {ApiOfflineSettings} from './api-offline.interceptor.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiOfflineStore {
  private isOnlineSubject = new BehaviorSubject(null);
  private hasPendingRequests = new BehaviorSubject(false);
  isOnline$: Observable<boolean> = this.isOnlineSubject.asObservable();
  hasPendingRequests$: Observable<boolean> = this.hasPendingRequests.asObservable();
  private pendingRequests: HttpRequest<any>[] = [];

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
  ) {
    // noinspection JSIgnoredPromiseFromCall
    this.init();
  }

  async init() {
    const networkStatus = await Plugins.Network.getStatus();
    this.setIsOnline(networkStatus.connected);
    Plugins.Network.addListener('networkStatusChange', (status) => {
      this.setIsOnline(!!status.connected);
    });

    this.isOnline$.subscribe(isOnline => {
      if (isOnline) {
        this.retryPendingRequests();
      }
    });

    // uncomment this to debug offline mode
    // interval(30 * 1000).subscribe(() => this.setIsOnline(!this.isOnline()));
  }

  private setIsOnline(isOnline: boolean) {
    this.logger.debug('[ApiOfflineStore] is online: ' + isOnline);
    this.isOnlineSubject.next(isOnline);
  }

  public isOnline(): boolean {
    return this.isOnlineSubject.getValue();
  }

  private retryPendingRequests() {
    if (this.pendingRequests.length === 0) {
      return;
    }
    this.logger.debug('[ApiOfflineStore] retry pending requests (' + this.pendingRequests.length + ' requests)');
    this.pendingRequests.forEach(request => this.http.request(request));
    this.pendingRequests = [];
  }

  handleRequestError(request, settings: ApiOfflineSettings) {
    this.logger.debug('[ApiOfflineInterceptor] added pending request ' + request.route);
    this.pendingRequests.push(request.clone());
    return true;
  }
}
