import {Injectable} from '@angular/core';
import {Plugins, PushNotification, PushNotificationActionPerformed, PushNotificationToken} from '@capacitor/core';
import {NGXLogger} from 'ngx-logger';
import {ProfileStore} from '../../auth/services/profile.store';
import {ApiService} from '../../core/services/api.service';
import {APIEndpoints} from '../../../environments/api-endpoints';
import {DoctorProfile, Profile, Role} from '../../auth/models';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  private token: PushNotificationToken;
  private platform: 'ios' | 'android' | 'electron' | 'web';
  private supported;
  private profile: Profile;

  constructor(
    private logger: NGXLogger,
    private profileStore: ProfileStore,
    private apiService: ApiService,
    private router: Router,
  ) {
  }

  init(platform: 'ios' | 'android' | 'electron' | 'web') {
    this.logger.debug('[PushService] init');

    this.platform = platform;
    this.supported = this.platform === 'android' || this.platform === 'ios';
    if (!this.supported) {
      this.logger.debug('[PushService] web push not supported');
      return;
    }

    this.profileStore.profile$.subscribe((profile) => {
      this.profile = profile;
      this.register();
    });

    /**
     * @see https://capacitor.ionicframework.com/docs/guides/push-notifications-firebase/
     * @see https://firebase.google.com/docs/android/setup?authuser=0
     * @see https://devdactic.com/push-notifications-ionic-capacitor/
     */
    const {PushNotifications} = Plugins;
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        PushNotifications.register();
      } else {
        this.logger.debug('[PushService] permission not granted');
      }
    });
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        this.token = token;
        this.logger.debug('[PushService] registration success, token: ' + token.value);
        this.register();
      }
    );
    PushNotifications.addListener('registrationError',
      (error: any) => {
        this.logger.warn('[PushService]  error on registration: ', error);
      }
    );
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        this.logger.debug('[PushService]  received: ', notification);
        // this.handleNotification(notification);
      }
    );
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        this.logger.debug('[PushService]  action performed: ', notification);
        this.handleNotification(notification.notification);
      }
    );
  }

  handleNotification(notification: PushNotification) {
    const data = notification.data;

    this.logger.debug('[PushService] handleNotification data: ' + JSON.stringify(data));
    // CANCEL_PONY_SHIPMENT
    if (!data.type) {
      this.logger.warn('[PushService] push not managed - invalid type');
      return;
    }
    this.logger.debug('[PushService] notifica di tipo: ' + data.type);

    const section = this.profile instanceof DoctorProfile ? 'doctor' : 'patient';

    switch (data.type) {
      case 'appointment':
        this.router.navigateByUrl(`/${section}/appointments?date=${data.date}`);
        break;
      case 'chat':
        if(data.subtype === 'new') {
          if(this.profile instanceof DoctorProfile) {
            this.router.navigateByUrl(`/${section}/patients/${data.patient_id}/chat`);
          } else {
            this.router.navigateByUrl(`/${section}/chat`);
          }
        }
        break;
    }
  }

  private register() {
    if (!this.profile || this.profile.hasRole(Role.Anonymous) || !this.token || !this.platform) {
      return;
    }
    this.logger.debug('[PushService] PASSED');

    const data = {
      token: this.token.value,
      platform: this.platform.toUpperCase(),
    };

    this.logger.warn('[PushService] registering push token via api', data.token);
    this.apiService.post(APIEndpoints.postDevice, data).subscribe(_ => {
      this.logger.warn('[PushService] done registering');
    });
  }

  clean() {
    if (!this.supported)
      return;
    const {PushNotifications} = Plugins;
    // noinspection JSIgnoredPromiseFromCall
    PushNotifications.removeAllDeliveredNotifications().then(
      () => this.logger.warn('[PushService] done clean')
    );
  }
}
