import {Component} from '@angular/core';
import {MenuController} from '@ionic/angular';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../auth/services/authentication.service';
import {ProfileStore} from '../../auth/services/profile.store';
import {NavSidebarMenuItems} from '../menu';

@Component({
  selector: 'app-nav-sidebar',
  template: `
    <ion-menu menuId="menu-main" contentId="main" side="end">
      <ion-img class="app-form-logo" src="assets/image/login-logo.svg"></ion-img>
      <ion-icon class="comp-window-close" src="assets/icon/window-close.svg" (click)="hideMenu()"></ion-icon>

      <div class="comp-buttons">
        <ion-button
          *ngFor="let item of items;"
          class="app-button-rounded primary"
          size="large" expand="block" mode="ios"
          (click)="hideMenu()"
          [routerLink]="item.href"
        >
          <div slot="start">
            <ion-icon [name]="item.iconName" [src]="item.iconSrc" *ngIf="item.iconName || item.iconSrc"></ion-icon>
            <span class="comp-caption">{{item.title | translate}}</span>
          </div>
        </ion-button>
      </div>
      <div class="comp-logout">
        <ion-button
          class="app-button-rounded comp-squared"
          color="danger" size="large" expand="block" mode="ios"
          (click)="logout()"
        >
          <div slot="start">
            <ion-icon src="assets/icon/window-close.svg"></ion-icon>
            <span class="comp-caption">Esci</span>
          </div>
        </ion-button>
      </div>
    </ion-menu>
  `,
  styles: [`
    .app-form-logo {
      margin: 9vh auto;
    }

    ion-menu {
      --background: #F5F5F5;
      --width: 330px;
    }

    ion-button.comp-squared {
      --border-radius: 0;
    }

    .comp-buttons {
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
      width: 100%;
    }

    .comp-buttons,
    .comp-logout {
      padding: 16px;
      text-align: center;
    }

    .comp-logout {
      position: absolute;
      bottom: 16px;
      width: 100%;
    }

    .comp-window-close {
      position: absolute;
      top: 16px;
      left: 16px;
      font-size: 32px;
      color: var(--app-text-color-primary);
    }
  `],
})
export class NavSidebarComponent {
  items  = [];

  constructor(
    private menu: MenuController,
    private authService: AuthenticationService,
    profileStore: ProfileStore,
    private router: Router
  ) {
    profileStore.profile$.subscribe(
      profile => profile
        ? this.items = NavSidebarMenuItems.filter(item => profile.hasRole(item.roles))
        : []
    );
  }

  logout() {
    this.hideMenu();
    this.authService.logout();
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(['login']);
  }

  hideMenu() {
    // noinspection JSIgnoredPromiseFromCall
    this.menu.close('menu-main');
  }
}
