import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { of } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { ProfileStore } from "./profile.store";
import { catchError, map, take } from "rxjs/operators";
import { Profile, Role } from "../models";
import { NGXLogger } from "ngx-logger";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private profileStore: ProfileStore,
    private authenticationService: AuthenticationService,
    private router: Router,
    private logger: NGXLogger
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const roles = next.data && next.data.roles;
    if (!roles) {
      return true;
    }

    return this.profileStore.profile$.pipe(
      take(1),
      map(
        (profile) =>
          this.validateProfile(profile, roles, state.url) &&
          this.validateGdpr(profile, roles, state.url) &&
          this.validateRole(profile, roles)
      ),
      catchError((err) => {
        this.logger.debug("[AuthGuard] Error", err);
        return of(false);
      })
    );
  }

  private validateProfile(profile: Profile, roles: Role[], currentUrl: string) {
    const isAnonymous = roles.includes(Role.Anonymous);
    if (!isAnonymous && !profile.fiscal_code) {
      const path = profile.getDashboardRoute() + "/profile";
      if (path !== currentUrl) {
        this.logger.debug(
          "[AuthGuard] missing fiscal_code, redirecting ",
          path
        );
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl(path);
        return false;
      }
    }
    return true;
  }

  private validateGdpr(profile: Profile, roles: Role[], currentUrl: string) {
    const isAnonymous = roles.includes(Role.Anonymous);
    if (!isAnonymous && !profile.gdpr) {
      const path = profile.getDashboardRoute() + "/privacy";
      const profilePath = profile.getDashboardRoute() + "/profile";

      if (path !== currentUrl && profilePath !== currentUrl) {
        this.logger.debug("[AuthGuard] missing gdpr, redirecting ", path);
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl(path);
        return false;
      }
    }
    return true;
  }

  private validateRole(profile: Profile, roles: Role[]) {
    const isAnonymous = roles.includes(Role.Anonymous);
    if (!profile.hasRole(roles)) {
      const path = isAnonymous ? profile.getDashboardRoute() : "login";
      this.logger.debug("[AuthGuard] missing roles, redirecting", path);
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigateByUrl(path);
      return false;
    }
    return true;
  }
}
