/* tslint:disable:variable-name */
import {Model} from '../core/models';
import {SpecializationAreas} from './constants';

export class Doctor extends Model {
  id: number;
  first_name: string;
  last_name: string;
  city: string;
  specialization_area: string;
  avatar: string;
  phone: string;


  deserialize(input: any): this {
    return super.deserialize({
      ...input,
      avatar: input.avatar || 'assets/icon/user-circle.svg',
      phone: input.phone.replace('+39', ''),
    });
  }

  getFullName(): string {
    return this.first_name + ' ' + this.last_name;
  }

  getSpecializationAreaLabel(): string {
    return SpecializationAreas.find(x => x.key === this.specialization_area).label
  }
}

export enum PatientRequestStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Refused = 'refused',
  Canceled = 'canceled',
}

const cssClassMap = {
  [PatientRequestStatus.Pending]: 'is-yellow',
  [PatientRequestStatus.Accepted]: 'is-green',
  [PatientRequestStatus.Refused]: 'is-red',
  [PatientRequestStatus.Canceled]: 'is-red',
};
const ionicColorMap = {
  [PatientRequestStatus.Pending]: 'warning',
  [PatientRequestStatus.Accepted]: 'success',
  [PatientRequestStatus.Refused]: 'danger',
  [PatientRequestStatus.Canceled]: 'danger',
};

const labelMap = {
  [PatientRequestStatus.Pending]: 'in attesa',
  [PatientRequestStatus.Accepted]: 'accettato',
  [PatientRequestStatus.Refused]: 'rifiutato',
  [PatientRequestStatus.Canceled]: 'cancellato',
};

export class PatientRequest extends Model {
  id: number;
  patient_id: number;
  doctor_id: number;
  status: PatientRequestStatus;
  name: string;
  avatar: string;
  phone: string;

  deserialize(input: any): this {
    return super.deserialize({
      ...input,
      status: input.status as PatientRequestStatus
    });
  }

  getCssClass(): string {
    return cssClassMap[this.status];
  }

  getStatusIonicColor(): string {
    return ionicColorMap[this.status];
  }

  getStatusLabel(): string {
    return labelMap[this.status];
  }

  isPending(): boolean {
    return this.status == PatientRequestStatus.Pending
  }
}
