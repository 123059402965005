import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {ProfileStore} from './profile.store';
import {Profile} from '../models';

@Injectable({providedIn: 'root'})
export class ProfileResolver implements Resolve<Profile> {
  private profile;

  constructor(
    private profileStore: ProfileStore
  ) {
    profileStore.profile$.subscribe(x => {
      this.profile = x
    });
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this.profile;
  }
}
