
const SPECIALIZATION_AREA_GENERAL = 'general';
const SPECIALIZATION_AREA_CARDIOVASCULAR = 'cardiovascular';
const SPECIALIZATION_AREA_METABOLIC = 'metabolic';
const SPECIALIZATION_AREA_RESPIRATORY = 'respiratory';

export const SpecializationAreas = [
  {
    key: SPECIALIZATION_AREA_GENERAL,
    label: 'Area Medicina Generale',
    icon: 'assets/icon/user-md.svg',
  },
  {
    key: SPECIALIZATION_AREA_CARDIOVASCULAR,
    label: 'Area Cardiovascolare',
    icon: 'assets/icon/heart.svg',
  },
  {
    key: SPECIALIZATION_AREA_METABOLIC,
    label: 'Area Metabolica',
    icon: 'assets/icon/measurement/weight.svg',
  },
  {
    key: SPECIALIZATION_AREA_RESPIRATORY,
    label: 'Area Respiratoria',
    icon: 'assets/icon/lungs.svg',
  },
]
