import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {PathologyStore} from './pathology.store';
import {Pathology} from '../models';
import {tap} from "rxjs/operators";
import {NotifierService} from "angular-notifier";

@Injectable({providedIn: 'root'})
export class PathologyResolver implements Resolve<Pathology> {
  constructor(
    private pathologyStore: PathologyStore,
    private router: Router,
    private notifier: NotifierService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const id = parseInt(route.paramMap.get('pathologyId'), 10);
    const ret = this.pathologyStore.getById(id);
    ret
      .pipe(
        tap(x => {
          if(!x) {
            this.notifier.notify('error', 'Patologia non supportata');
            this.router.navigateByUrl('/')
          };
        })
      )
      .subscribe(x => this.pathologyStore.setCurrent(x));
    return ret;
  }
}
