import {Role, RoleEnum} from '../auth/models';

export interface MenuItem {
  title?: string;
  href: string | false; // use false in order to handle disabled link
  iconSrc?: string;
  iconName?: string;
  roles?: Role | Role[] | RoleEnum | RoleEnum[];
  // used in app-center-buttons
  class?: string;
  colClass?: string;
  onClick?: () => void;
  isHeader?: boolean;
  hasUnreadBadge?: boolean;
  badgeValue?: number;
}

export const NavHeaderMenuItems: MenuItem[] = [
  {
    href: '/doctor',
    roles: Role.Doctor,
    title: 'Bacheca'
  },
  {
    href: '/doctor/pathologies',
    roles: Role.Doctor,
    title: 'Patologie'
  },
  {
    href: '/doctor/patients',
    roles: Role.Doctor,
    title: 'Pazienti'
  },
  {
    href: '/doctor/appointments',
    roles: Role.Doctor,
    title: 'Agenda'
  },
];


export const NavSidebarMenuItems: MenuItem[] = [
  {
    href: '/doctor',
    iconSrc: 'assets/icon/rocket-launch.svg',
    title: 'Bacheca',
    roles: Role.Doctor
  },
  {
    href: '/doctor/profile',
    iconSrc: 'assets/icon/user.svg',
    title: 'Profilo',
    roles: Role.Doctor
  },
  {
    href: '/doctor/appointments',
    iconSrc: 'assets/icon/calendar-alt.svg',
    title: 'Agenda',
    roles: Role.Doctor
  },
  {
    href: '/patient',
    iconSrc: 'assets/icon/rocket-launch.svg',
    title: 'Bacheca',
    roles: Role.Patient
  },
  {
    href: '/patient/profile',
    iconSrc: 'assets/icon/user.svg',
    title: 'Profilo',
    roles: Role.Patient
  },
  {
    href: '/patient/appointments',
    iconSrc: 'assets/icon/calendar-alt.svg',
    title: 'Agenda',
    roles: Role.Patient
  },
];

export const NavFooterMenuItems: MenuItem[] = [
  {
    href: '/doctor',
    iconSrc: 'assets/icon/rocket-launch.svg',
    roles: Role.Doctor
  },
  {
    href: '/doctor/patients',
    iconSrc: 'assets/icon/users.svg',
    roles: Role.Doctor
  },
  {
    href: '/doctor/pathologies',
    iconSrc: 'assets/icon/stethoscope.svg',
    roles: Role.Doctor
  },
  {
    href: '/doctor/appointments',
    iconSrc: 'assets/icon/calendar-alt.svg',
    roles: Role.Doctor
  },
  {
    href: '/doctor/profile',
    iconSrc: 'assets/icon/user.svg',
    roles: Role.Doctor
  },
  {
    href: '/patient',
    iconSrc: 'assets/icon/rocket-launch.svg',
    roles: Role.Patient
  },
  {
    href: '/patient/appointments',
    iconSrc: 'assets/icon/calendar-alt.svg',
    roles: Role.Patient
  },
  {
    href: '/patient/profile',
    iconSrc: 'assets/icon/user.svg',
    roles: Role.Patient
  },
];

export const PlusModalMenuItems: MenuItem[] = [
  {
    href: '/doctor/appointments-add',
    iconSrc: 'assets/icon/calendar-alt.svg',
    title: 'Nuovo appuntamento',
    roles: Role.Doctor
  },
  {
    href: '/doctor/patients-add',
    iconSrc: 'assets/icon/users.svg',
    title: 'Nuovo Paziente',
    roles: Role.Doctor
  },
  {
    href: '/doctor/therapies-add',
    iconSrc: 'assets/icon/pills.svg',
    title: 'Nuovo Farmaco',
    roles: Role.Doctor
  },
]
