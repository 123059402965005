import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {JwtBearerStore} from '../services/jwt-bearer.store';
import {isPublicEndpoint} from '../../../environments/api-endpoints';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService} from '../../auth/services/authentication.service';

const DEBUG = false;

@Injectable()
export class JwtBearerInterceptor implements HttpInterceptor {
  constructor(
    private store: JwtBearerStore,
    private logger: NGXLogger,
    private authenticationService: AuthenticationService
  ) {

  }

  isSecured(url) {
    return !isPublicEndpoint(url);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isSecured(request.url)) {
      return this.store.getToken()
        .pipe(
          catchError(error => this.handleJwtTokenError(error)),
          switchMap((token) => {
            if (DEBUG) {
              console.log(request);
            }
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
            return next.handle(request);
          }),
        );
    } else {
      return next.handle(request);
    }
  }

  handleJwtTokenError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.authenticationService.logout();
    return throwError(errorMessage);
  }
}
