import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  static getOptions(options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      ...(options || {}),
    };
  }

  static makeHttpParams(params) {
    return new HttpParams({
      fromObject: params
    });
  }

  static processBody(body: any) {
    return body instanceof FormData
      ? body
      : (body ? JSON.stringify(body) : null);
  }

  get(path: string, params: HttpParams | {} = new HttpParams(), options: {} = {}): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, {params, ...options});
  }

  put(
    path: string,
    body: object = {},
    params ?: HttpParams,
    options: object = {}
  ): Observable<any> {
    return this.http.put(
      `${environment.api_url}${path}`,
      ApiService.processBody(body),
      ApiService.getOptions(params ? {params, ...options} : options)
    );
  }


  patch(
    path: string,
    body: object = {},
    params ?: HttpParams,
    options: object = {}
  ): Observable<any> {
    return this.http.patch(
      `${environment.api_url}${path}`,
      ApiService.processBody(body),
      ApiService.getOptions(params ? {params, ...options} : options)
    );
  }

  post(
    path: string,
    body ?: object,
    params ?: HttpParams,
    options: object = {}
  ): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      ApiService.processBody(body),
      ApiService.getOptions(params ? {params, ...options} : options)
    );
  }


  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`
    );
  }
}
