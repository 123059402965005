import {Model} from '../core/models';
import * as moment from 'moment';

export class Pathology extends Model {
  id: number;
  name: string;

  deserialize(input: any): this {
    return super.deserialize({
      ...input
    });
  }

  getIcon() {
    return 'assets/icon/pathology/' + this.id + '.svg';
  }
}
