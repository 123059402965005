import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../environments/environment';

import {Angulartics2GoogleGlobalSiteTag} from "angulartics2/gst";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private logger: NGXLogger
  ) {
  }

  init() {
    if (!environment.analytics) {
      this.logger.debug('[AnalyticsService] disabled')
      return;
    }
    this.angulartics.startTracking();
    this.logger.debug('[AnalyticsService] started');
  }
}
