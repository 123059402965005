import {HttpParams} from '@angular/common/http';

export interface ApiOfflineSettings {
  route?: string;
  location?: object;
  forced?: boolean;
}

export class ApiOfflineInterceptorHttpParams extends HttpParams {
  constructor(
    public interceptorConfig: ApiOfflineSettings,
    options
  ) {
    super(options);
  }
}
