import { NgxLoggerLevel } from 'ngx-logger';
import { AppEnvironment } from './types';

export function generateCommon(): AppEnvironment {
  return {
    production: false,
    wp_url: 'https://blog.sanilabplus.it',
    register_url: 'http://127.0.0.1:8000',
    api_url: 'http://127.0.0.1:8000',
    logging: {
      level: NgxLoggerLevel.DEBUG
    },
    NOTIFICATION_HIDE_DELAY: 3000,
    MAX_AJAX_TIMEOUT: 15000,
    sentry: {
      dsn: "https://2ddbd2ee48a4476bb9c0bbcee271d500@o510715.ingest.sentry.io/5725454",
      tracingOrigins: ["localhost", "https://app.iomicuro.it/api"],
      tracesSampleRate: 0
    },
    analytics: {
      GA_MEASUREMENT_ID: 'G-PB9866S67V'
    }
  };
}
