import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NavSidebarComponent} from './components/nav-sidebar.component';
import {NavHeaderComponent} from './components/nav-header.component';
import {NavFooterComponent} from './components/nav-footer.component';
import {PhonePipe} from './pipes/phone.pipe';
import {MomentPipe} from './pipes/moment.pipe';
import {CenterButtonsComponent} from './components/center-buttons.component';
import {PlusModalComponent} from './components/plus-modal.component';
import {HtmlContentComponent} from "./components/html-content";
import {WpHtmlContentComponent} from "./components/wp-html-content.component";
import {Nl2brPipe} from "./pipes/nl2br.pipe";
import {IsDesktopDirective} from "./directives/is-desktop.directive";
import {LoadingComponent} from "./components/loading";
import {BoxTitleComponent} from "./components/box-title";
import {BreadcrumbsComponent} from "./components/breadcrumbs";
import {ImagePickerComponent} from "./components/image-picker.component";
import {PreviewPhotoModalPage} from "./modals/preview-photo.modal";
import {NavHeaderDesktopComponent} from "./components/nav-header-desktop.component";
import {NavHeaderMobileComponent} from "./components/nav-header-mobile.component";

@NgModule({
  declarations: [
    NavSidebarComponent,
    NavHeaderComponent,
    NavHeaderDesktopComponent,
    NavHeaderMobileComponent,
    NavFooterComponent,
    CenterButtonsComponent,
    PlusModalComponent,
    HtmlContentComponent,
    WpHtmlContentComponent,
    LoadingComponent,
    BoxTitleComponent,
    BreadcrumbsComponent,
    ImagePickerComponent,

    PreviewPhotoModalPage,

    PhonePipe,
    MomentPipe,
    Nl2brPipe,

    IsDesktopDirective,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
  ],
  exports: [
    NavSidebarComponent,
    NavHeaderComponent,
    NavHeaderDesktopComponent,
    NavHeaderMobileComponent,
    NavFooterComponent,
    CenterButtonsComponent,
    PlusModalComponent,
    HtmlContentComponent,
    WpHtmlContentComponent,
    LoadingComponent,
    BoxTitleComponent,
    BreadcrumbsComponent,
    ImagePickerComponent,

    PhonePipe,
    MomentPipe,
    Nl2brPipe,

    IsDesktopDirective,
  ],
  entryComponents: []
})
export class CoreModule {
}
